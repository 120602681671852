import apiClient from "src/utils/apiClient";
import store from "src/store/store";
import { createToast } from "src/store/toastsSlice";
import { TOAST_TIMEOUT } from "src/config/Toast";
import { getBatchJobs } from "src/store/batchExtractionSlice";

export interface SingleExtractionPayload {
  text: string;
  modelId: string;
  generateLLMClarification: boolean;
}

export interface TranslationPayload {
  text: string;
}

export interface BatchExtractionPayload {
  file: File;
  modelId: string;
  generateLLMClarification: boolean;
}

class ExtractionService {
  async singleExtraction(payload: SingleExtractionPayload) {
    return await apiClient.post("/extractions/single", payload);
  }

  async batchExtraction(payload: BatchExtractionPayload) {
    const formData = new FormData();
    formData.append("file", payload.file);
    formData.append("model_id", payload.modelId);
    formData.append(
      "generate_llm_clarification",
      JSON.stringify(payload.generateLLMClarification),
    );
    const res = await apiClient.post("/extractions/batch", formData);
    if (res) {
      store.dispatch(
        createToast({
          type: "success",
          message: "Batch job submit successfully.",
          timeout: TOAST_TIMEOUT,
        }),
      );
      store.dispatch(getBatchJobs());
    }
    return res;
  }
}

export default new ExtractionService();
