import React, { useState } from "react";
import Text from "@amzn/meridian/text";
import Row from "@amzn/meridian/row";
import Select, { SelectOption } from "@amzn/meridian/select";
import Divider from "@amzn/meridian/divider";
import Modal, { ModalFooter } from "@amzn/meridian/modal";
import { useAppDispatch, useAppSelector } from "src/store/store";
import Icon from "@amzn/meridian/icon";
import SearchField from "@amzn/meridian/search-field";
import documentTokens from "@amzn/meridian-tokens/base/icon/document";
import Column from "@amzn/meridian/column";
import filterTokens from "@amzn/meridian-tokens/base/icon/filter";
import Button from "@amzn/meridian/button";
import {
  colorBlue300,
  colorForest100,
  colorForest400,
  colorRed500,
} from "@amzn/meridian-tokens/base/color";
import { css } from "@emotion/css";
import { useEffect } from "react";
import { timeAgo } from "src/utils/dateUtil";
import {
  AuditJob,
  AuditJobStatus,
  resetNewCreatedAuditJobId,
} from "src/store/auditDataUploadSlice";
import { useNavigate } from "react-router-dom";
import { createSearchRegExp } from "src/helpers";
import DateRangePicker from "@amzn/meridian/date-range-picker";
import "./auditJobView.scss";

const blueText = css({
  color: colorBlue300,
});

const greenText = css({
  color: colorForest400,
});

const redText = css({
  color: colorRed500,
});

const AuditJobView = () => {
  const { auditJobs, newCreatedAuditJobId } = useAppSelector(
    (state) => state.auditDataUploadSlice,
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const iniQueries = [""];
  const INI_EMPTY_STRING = "";
  const [query, setQuery] = useState<string>(INI_EMPTY_STRING);
  const [queries, setQueries] = useState<string[]>(iniQueries);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [dateRange, setDateRange] = useState([]);
  // keep this code for future usage
  const [workstream, setWorkstream] = useState(INI_EMPTY_STRING);
  const [user, setUser] = useState(INI_EMPTY_STRING);

  useEffect(() => {
    if (newCreatedAuditJobId !== "") {
      setTimeout(() => {
        dispatch(resetNewCreatedAuditJobId());
      }, 5000);
    }
  }, [newCreatedAuditJobId]);

  const filterByDateRange = (
    auditJobs: AuditJob[],
    dateRange: string[],
  ): AuditJob[] => {
    if (dateRange.length === 0) {
      return auditJobs;
    }

    const [startDate, endDate] = dateRange;
    return auditJobs.filter((job) => {
      const jobDate = new Date(job.timestamp);
      const start = new Date(startDate);
      const end = new Date(endDate);
      return jobDate >= start && jobDate <= end;
    });
  };

  const filterAuditJobsByQueries = (
    queries: string[],
    auditJobs: AuditJob[],
    dateRange: string[],
  ): AuditJob[] => {
    if (queries.every((query) => query === INI_EMPTY_STRING)) {
      return filterByDateRange(auditJobs, dateRange);
    }

    const nonEmptyQueries = queries.filter((query) => query.length > 0);
    const searchRegExps = nonEmptyQueries.map(createSearchRegExp);

    return auditJobs.filter((job) => {
      const { modelName, ownerAlias, inputFileName, timestamp } = job;
      const searchFields = [modelName, ownerAlias, inputFileName, timestamp];

      const matchesQuery = searchRegExps.some((regex) =>
        searchFields.some((field) => regex.test(field)),
      );

      if (dateRange.length === 0) {
        return matchesQuery;
      }

      const matchesDateRange = filterByDateRange([job], dateRange).length > 0;

      return matchesQuery && matchesDateRange;
    });
  };

  const getUniqueValues = (auditJobs: AuditJob[], field: keyof AuditJob) => {
    return Array.from(new Set(auditJobs.map((auditJob) => auditJob[field])));
  };

  const resetSearchQueries = () => {
    setDateRange([]);
    setWorkstream(INI_EMPTY_STRING);
    setUser(INI_EMPTY_STRING);
    setQueries(iniQueries);
  };

  return (
    <Column spacing={"500"}>
      <Row width="100%" alignmentHorizontal={"start"}>
        <Text type={"h400"}>Your Extraction Requests</Text>
      </Row>
      <Column type="fill" spacingInset="300">
        <Row widths={["30", "70%"]}>
          <SearchField
            value={query}
            onChange={(query) => {
              setQuery(query);
              setQueries([query]);
            }}
            placeholder="Search for..."
            onSubmit={() => {}}
            searchButton={true}
          />
          <Column spacing={"none"} alignmentHorizontal="end">
            <div
              className="placements-overview__filter-icon"
              onClick={() => {
                setIsFilterModalOpen(true);
              }}
            >
              <Icon tokens={filterTokens}></Icon>
            </div>
          </Column>
        </Row>
      </Column>
      <Column spacing={"200"}>
        <Row
          widths={["25%", "25%", "25%", "25%"]}
          alignmentHorizontal={"start"}
        >
          <Text type={"h200"}>Uploaded files</Text>
          <Text type={"h200"}>Workstream</Text>
          <Text type={"h200"}>Uploaded by</Text>
          <Text type={"h200"}>Actions</Text>
        </Row>
        {filterAuditJobsByQueries(queries, auditJobs, dateRange).map(
          (auditJob) => (
            <Column>
              <Divider spacingAfter="large" spacingBefore="large" />
              <Row
                alignmentHorizontal={"start"}
                widths={["25%", "25%", "25%", "25%"]}
                width={"100%"}
                backgroundColor={
                  auditJob.id === newCreatedAuditJobId ? colorForest100 : ""
                }
              >
                <Column spacing={"none"}>
                  <span className="filename-truncate">
                    <Text type={"h200"}>
                      <Icon className={blueText} tokens={documentTokens} />
                      {" " + auditJob.inputFileName}
                    </Text>
                  </span>
                  <Row widths={["10%"]}>
                    <Column></Column>
                    <Text color={"secondary"}>
                      {timeAgo(auditJob.timestamp)}
                    </Text>
                  </Row>
                </Column>
                <Column
                  alignmentVertical={"top"}
                  alignmentHorizontal={"start"}
                  overflowX={"auto"}
                >
                  <Text type={"h200"}>{" " + auditJob.modelName}</Text>
                </Column>
                <Column alignmentVertical={"top"} alignmentHorizontal={"start"}>
                  <Text>{auditJob.ownerAlias}</Text>
                </Column>
                <Column
                  alignmentVertical={"top"}
                  alignmentHorizontal={"start"}
                  data-cy={`${auditJob.inputFileName}-${auditJob.timestamp}__column`}
                >
                  {auditJob.status === AuditJobStatus.Completed ? (
                    <Button
                      onClick={() => {
                        navigate(`/audit/${auditJob.id}`);
                      }}
                      data-cy={`${auditJob.inputFileName}-${auditJob.timestamp}__button`}
                    >
                      Audit
                    </Button>
                  ) : (
                    <Text>{auditJob.status}</Text>
                  )}
                </Column>
              </Row>
            </Column>
          ),
        )}
      </Column>
      <React.Fragment>
        <Modal
          title="Filter"
          open={isFilterModalOpen}
          onClose={() => {
            setIsFilterModalOpen(false);
          }}
          scrollContainer="viewport"
          closeLabel="Close"
          width={600}
        >
          <Column>
            <Text>Choose filter criteria</Text>
            <Row widths={["25%"]}>
              <Text>Date Range</Text>
              <DateRangePicker
                value={dateRange}
                onChange={setDateRange}
                startLabel="Start date"
                endLabel="End date"
                monthsInView={2}
              />
            </Row>
            <Row widths={["25%"]}>
              <Text>Uploaded by</Text>
              <Select
                value={user}
                onChange={setUser}
                placeholder="Enter value..."
                width={210}
              >
                {getUniqueValues(auditJobs, "ownerAlias").map((alias) => (
                  <SelectOption value={alias} label={alias} />
                ))}
              </Select>
            </Row>
          </Column>
          <ModalFooter>
            <Row alignmentHorizontal="end" widths="fit">
              <Button
                type="secondary"
                size="small"
                onClick={() => {
                  setQueries([workstream, user]);
                  setIsFilterModalOpen(false);
                }}
              >
                Confirm
              </Button>
              <Button
                type="secondary"
                size="small"
                onClick={resetSearchQueries}
              >
                Clear
              </Button>
            </Row>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    </Column>
  );
};
export default AuditJobView;
