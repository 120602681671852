import React from "react";
import { TableCell } from "@amzn/meridian/table";
import Text from "@amzn/meridian/text";
import { GroundTruthField } from "src/store/modelLifeCycleGroundTruthSlice";

interface AnswerCellsProps {
  isBlind: boolean;
  randomizedOrder: boolean;
  groundTruthField: GroundTruthField;
  isAnswerMatch: (field: GroundTruthField, many: boolean) => boolean;
  isFieldMultiSelected: (field: GroundTruthField) => boolean;
  getAnswerLowerCaseAndSeparatebyComma: (answer: string) => string;
}

const AnswerCells: React.FC<AnswerCellsProps> = ({
  isBlind,
  randomizedOrder,
  groundTruthField,
  isAnswerMatch,
  isFieldMultiSelected,
  getAnswerLowerCaseAndSeparatebyComma,
}) => {
  const textColor = isAnswerMatch(
    groundTruthField,
    isFieldMultiSelected(groundTruthField),
  )
    ? "primary"
    : "error";

  if (isBlind && randomizedOrder) {
    return (
      <>
        <TableCell width={"20%"}>
          <Text color={textColor}>
            {getAnswerLowerCaseAndSeparatebyComma(groundTruthField.llmAnswer)}
          </Text>
        </TableCell>
        <TableCell width={"20%"}>
          <Text color={textColor}>
            {getAnswerLowerCaseAndSeparatebyComma(groundTruthField.userAnswer)}
          </Text>
        </TableCell>
      </>
    );
  }

  return (
    <>
      <TableCell width={"20%"}>
        <Text color={textColor}>
          {getAnswerLowerCaseAndSeparatebyComma(groundTruthField.userAnswer)}
        </Text>
      </TableCell>
      <TableCell width={"20%"}>
        <Text color={textColor}>
          {getAnswerLowerCaseAndSeparatebyComma(groundTruthField.llmAnswer)}
        </Text>
      </TableCell>
    </>
  );
};

export default AnswerCells;
